<template>
    <div class="text">
        Not found modal
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.text {
    text-align: center;

}

@media (max-width: 575px) {
    .text {
        font-size: 3vw;
    }
}
</style>
